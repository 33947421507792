import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { SectionLoadingState } from '../../components'
import { searchReviewTypes } from '../../constants/constants'
import { useSearchReviews } from '../../hooks'
import SearchReviewSearchQueryHistoricalReviewListPage from './SearchReviewSearchQueryHistoricalReviewListPage'
import SearchReviewSearchQueryLiteratureReviewListPage from './SearchReviewSearchQueryLiteratureReviewListPage'

export const FloatLeftContainer = styled.div`
  display: flex;
  margin-left: auto;
  order: 2;
  gap: 8px;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;
`

export const BadgesContainer = styled.div`
  display: flex;
  gap: 4px;
`

const searchReviewComponents = {
  [searchReviewTypes.HISTORICAL_NC_REVIEW]:
    SearchReviewSearchQueryHistoricalReviewListPage,
  [searchReviewTypes.LITERATURE_REVIEW]:
    SearchReviewSearchQueryLiteratureReviewListPage,
}

const SearchReviewSearchQueryDocumentListPage = () => {
  const params = useParams()
  const { selectedSearchReview } = useSearchReviews(params)

  const Component = selectedSearchReview?.reviewType
    ? searchReviewComponents[selectedSearchReview.reviewType] ||
      searchReviewComponents[searchReviewTypes.LITERATURE_REVIEW] // fallback to literature review
    : null

  return Component ? <Component /> : <SectionLoadingState />
}

export default SearchReviewSearchQueryDocumentListPage
