import { Form } from 'antd'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SearchQueryListTable } from '../../../components'
import { NO_DATA_PLACEHOLDER } from '../../../constants/constants'
import { useSearchReviews } from '../../../hooks'
import { RootState } from '../../../store'

interface SearchReviewDetailsOverviewQueriesPanelProps {
  handleSearchDelete: () => void
}

const SearchQueryListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const SearchReviewDetailsOverviewQueriesPanel = ({
  handleSearchDelete,
}: SearchReviewDetailsOverviewQueriesPanelProps) => {
  const { selectedSearchReview } = useSearchReviews({
    preventFetch: true,
  })
  const { currentUser } = useSelector((state: RootState) => state.user)
  const isCurrentUserOwner = useMemo(
    () => currentUser?.id === selectedSearchReview?.createdBy?.id,
    [selectedSearchReview, currentUser]
  )

  return (
    <div>
      <Form.Item>
        <SearchQueryListContainer>
          {selectedSearchReview?.searchQueries &&
          selectedSearchReview?.searchQueries.length >= 1 ? (
            <SearchQueryListTable
              searchQueries={selectedSearchReview?.searchQueries}
              searchReviewId={selectedSearchReview?.id}
              useDelete={isCurrentUserOwner}
              onDelete={handleSearchDelete}
            />
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </SearchQueryListContainer>
      </Form.Item>
    </div>
  )
}

export default SearchReviewDetailsOverviewQueriesPanel
