import { SorterResult } from 'antd/es/table/interface'
import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  TimePeriodValue,
  getStartAndEndDate,
} from '../components/SelectTimePeriod'
import { backEndSortOrder, urlList } from '../constants/constants'
import { apiService, auditTrailService } from '../services'
import { RootState } from '../store'
import {
  AuditTrail,
  setAuditTrails,
  setListCsv,
} from '../store/reducers/auditTrailReducer'
import { HttpResponse } from '../types/generalTypes'
import useDownloadFile from './useDownloadFile'

export interface AuditTrailsResponse extends HttpResponse {
  data: AuditTrail[]
}

interface UseAuditTrailsProps {
  preventFetch?: boolean
  timePeriod?: TimePeriodValue
  startDateParam?: string
  endDateParam?: string
  component?: string
}

const useAuditTrails = ({
  preventFetch = false,
  timePeriod,
  startDateParam,
  endDateParam,
  component,
}: UseAuditTrailsProps) => {
  const dispatch = useDispatch()
  const downloadCsv = useDownloadFile()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | Error>()
  const auditTrails: AuditTrailsResponse | undefined = useSelector(
    (state: RootState) => state.auditTrail.auditTrails
  )

  const { startDate, endDate } = getStartAndEndDate(
    timePeriod || 'ALL',
    startDateParam || '',
    endDateParam || ''
  )

  interface FetchAuditTrailListParams {
    page?: number
    sorterResult?: SorterResult<AuditTrail>
    refreshForceCall?: boolean
  }

  const fetchAuditTrailList = useCallback(
    ({
      page = 1,
      sorterResult = undefined,
      refreshForceCall = false,
    }: FetchAuditTrailListParams) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        const startDateUrl = !!startDate ? `&actionDateAfter=${startDate}` : ''
        const endDateUrl = !!endDate ? `&actionDateBefore=${endDate}` : ''
        const componentUrl = !!component ? `&requestComponent=${component}` : ''
        const sortUrl =
          !!sorterResult && sorterResult.order
            ? `&sortBy=${sorterResult.field}&sortOrder=${
                sorterResult.order === 'ascend'
                  ? backEndSortOrder.ASC
                  : backEndSortOrder.DESC
              }`
            : ''
        setLoading(true)
        apiService
          .fetchItems(
            `${urlList.Audit_TRAILS}?pageSize=100&page=${page}${sortUrl}${startDateUrl}${endDateUrl}${componentUrl}`
          )
          .then((response: AuditTrailsResponse) => {
            setLoading(false)
            dispatch(setAuditTrails(response))
          })
          .catch((error: AxiosError | Error) => {
            setError(error)
            console.error('axios fetch error', error)
            setLoading(false)
          })
      }
    },
    [dispatch, preventFetch, loading, startDate, endDate, component]
  )

  const refreshAuditTrailList = useCallback(
    async (
      page = 1,
      sorterResult: SorterResult<AuditTrail> | undefined = undefined
    ) => {
      fetchAuditTrailList({ page, sorterResult, refreshForceCall: true })
    },
    [fetchAuditTrailList]
  )

  useEffect(() => {
    fetchAuditTrailList({ page: 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportAuditTrailCsv = useCallback(() => {
    if (auditTrails) {
      auditTrailService
        .exportAuditTrailCsv()
        .then((response: string) => {
          const filename = 'audit_trail_list.csv'
          downloadCsv(response, filename)
          dispatch(setListCsv(response))
        })
        .catch((error: AxiosError) => {
          console.error('Error fetching audit trail list CSV data', error)
        })
    }
  }, [auditTrails, dispatch, downloadCsv])

  return {
    auditTrails: auditTrails?.data || [],
    auditTrailsTotal: auditTrails?.totalResults || 0,
    error,
    exportAuditTrailCsv,
    loading,
    refreshAuditTrailList,
  }
}

export default useAuditTrails
