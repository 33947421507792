import { FieldTimeOutlined } from '@ant-design/icons'
import { Form, Input } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { IconTextContainer } from '../../../components'
import { NO_DATA_PLACEHOLDER, dateFormat } from '../../../constants/constants'
import { useSearchReviews } from '../../../hooks'
import { RootState } from '../../../store'

const { TextArea } = Input

const SearchReviewDetailsOverviewInfoPanel = () => {
  const { selectedSearchReview } = useSearchReviews({
    preventFetch: true,
  })

  const searchReviewOwner = useMemo(
    () => selectedSearchReview?.createdBy?.displayName,
    [selectedSearchReview]
  )
  const searchReviewLastModifiedDate = useMemo(
    () => selectedSearchReview?.lastModifiedDate,
    [selectedSearchReview]
  )

  const reviewType = useMemo(
    () =>
      selectedSearchReview?.reviewType &&
      t(
        `searchReviewDetailsPage.searchReviewTypes.${selectedSearchReview?.reviewType}`
      ),
    [selectedSearchReview]
  )

  const { currentUser } = useSelector((state: RootState) => state.user)

  const isCurrentUserOwner = useMemo(
    () => currentUser?.id === selectedSearchReview?.createdBy?.id,
    [selectedSearchReview, currentUser]
  )
  return (
    <div>
      <Form.Item
        label={t('Title')}
        required
        name="title"
        rules={[
          { required: true, message: t('errorMessage.isRequired') || '' },
        ]}
      >
        <Input readOnly={!isCurrentUserOwner} />
      </Form.Item>
      <Form.Item label={t('Description')} name="description">
        <TextArea rows={4} readOnly={!isCurrentUserOwner} style={{}} />
      </Form.Item>
      <Form.Item label={t('Type')}>
        {(reviewType &&
          t(`${reviewType}`)) ||
          NO_DATA_PLACEHOLDER}
      </Form.Item>
      <Form.Item label={t('Owner')}>
        {searchReviewOwner || NO_DATA_PLACEHOLDER}
      </Form.Item>
      <Form.Item label={t('Last update')}>
        <IconTextContainer>
          <FieldTimeOutlined />

          {searchReviewLastModifiedDate ? (
            <Moment
              date={searchReviewLastModifiedDate}
              format={dateFormat.PRIMARY}
            />
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </IconTextContainer>
      </Form.Item>
    </div>
  )
}

export default SearchReviewDetailsOverviewInfoPanel
