import { LoginOutlined } from '@ant-design/icons'
import { Alert, Button, Card, Form, Input, theme, Tooltip } from 'antd'
import { t } from 'i18next'
import { KeyboardEvent, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useClearStore } from '../../hooks'
import authService from '../../services/authService'
import notificationService from '../../services/notificationService'
import { routePaths } from '../RootPage'

const LoginLayout = styled.div`
  display: flex;
  padding: 32px 0; // 96px - 64px (there is a 64px margin for all content)
  justify-content: center;
  background-color: ${({ theme }) => theme.token.colorBgLayout};
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`

const AlertContainer = styled.div`
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 16px;
  text-align: center;
`

const StyledAlert = styled(Alert)`
  margin: 0 auto;
  width: max-content;
`

const StyledInfoAlert = styled(Alert)`
  margin: 0 auto;
  width: max-content;
  background-color: ${({ theme }) => theme.token.colorPrimaryBg};
  border: 1px solid ${({ theme }) => theme.token.colorPrimaryBorder};
  .ant-alert-icon {
    color: #7a40ea !important;
  }
`

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
`

const StyledDescription = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 20px;
`

interface LoginFormValues {
  email: string
  password: string
}

const { useToken } = theme

const LoginPage = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const location = useLocation()
  const { token } = useToken()
  const clearStore = useClearStore()
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showSessionExpiredAlert, setShowSessionExpiredAlert] = useState(false)
  const [showCapsLockWarning, setShowCapsLockWarning] = useState(false)

  const handleLogin = async (formValue: LoginFormValues) => {
    clearStore() // Prevent data from older session to still be there when we log in
    const { email, password } = formValue
    setLoading(true)
    await authService.login(email, password).then(
      (response: any) => {
        setLoading(false)
        navigate(routePaths.SEARCH)
      },
      (error) => {
        console.error('Login error', error)
        setLoading(false)
        setShowAlert(true)
      }
    )
  }

  const handleCapsLock = (event: KeyboardEvent) => {
    if (event.getModifierState('CapsLock')) {
      setShowCapsLockWarning(true)
    } else {
      setShowCapsLockWarning(false)
    }
  }

  const handleForgotPassword = () => {
    navigate(routePaths.FORGOT_PASSWORD)
  }

  useEffect(() => {
    const basePath = location.pathname
    if (basePath === routePaths.LOGIN_UNAUTHORIZED) {
      notificationService.notificationError(t('errorMessage.sessionExpired'))
      setShowSessionExpiredAlert(true)
    }
  }, [location.pathname])

  return (
    <>
      {showAlert && (
        <AlertContainer>
          <StyledAlert
            type="error"
            message={t('errorMessage.loginAlertMessageCredential')}
          />
        </AlertContainer>
      )}
      {showSessionExpiredAlert && (
        <AlertContainer data-testid="session-expired-alert">
          <StyledInfoAlert
            type="info"
            message={t('errorMessage.sessionExpired')}
            showIcon
            closable
          />
        </AlertContainer>
      )}
      <LoginLayout>
        <Card
          bordered={false}
          styles={{
            body: {
              paddingBottom: '16px',
              width: '330px',
              backgroundColor: token.colorBgElevated,
            },
          }}
        >
          <Form
            name="normal_login"
            onFinish={handleLogin}
            form={form}
            requiredMark={false}
            data-testid="form"
          >
            <StyledTitle>{t('loginPage.title')}</StyledTitle>
            <StyledDescription>{t('loginPage.introduction')}</StyledDescription>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t('errorMessage.emailIsRequired') || '',
                },
              ]}
            >
              <Input
                placeholder={t('Email') || ''}
                disabled={loading}
                data-testid="email"
              />
            </Form.Item>
            <Tooltip
              title={t('loginPage.capsLockWarning')}
              open={showCapsLockWarning}
            >
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('errorMessage.wrongPassword') || '',
                  },
                  { min: 8, message: t('errorMessage.wrongPassword') || '' },
                ]}
              >
                <Input
                  type="password"
                  placeholder={t('Password') || ''}
                  disabled={loading}
                  onKeyDown={(event) => handleCapsLock(event)}
                  data-testid="password"
                />
              </Form.Item>
            </Tooltip>
            <ButtonContainer>
              <Button
                type="text"
                onClick={handleForgotPassword}
                style={{
                  whiteSpace: 'pre-line',
                  height: 'auto',
                }}
              >
                {t('loginPage.forgotPassword')}
              </Button>
              <Button
                icon={<LoginOutlined />}
                type="primary"
                htmlType="submit"
                data-testid="submit"
              >
                {t('loginPage.loginButton')}
              </Button>
            </ButtonContainer>
          </Form>
        </Card>
      </LoginLayout>
    </>
  )
}

export default LoginPage
