import {
  QualityManagementSmartFunctionType,
  qualityManagementSmartFunctionTypes,
} from './constants'

export interface QualityManagementWorkspaceSmartFunction {
  id: string
  titleKey: string
  qualityManagementSmartFunctionType: QualityManagementSmartFunctionType
}

export type QualityManagementWorkspaceData =
  QualityManagementWorkspaceSmartFunction[]

export const qualityManagementWorkspaceData: QualityManagementWorkspaceData = [
  {
    id: '1',
    titleKey: 'daviationClassification',
    qualityManagementSmartFunctionType:
      qualityManagementSmartFunctionTypes.DEVIATION_CLASSIFICATION,
  },
  {
    id: '2',
    titleKey: 'rootCauseDiscovery',
    qualityManagementSmartFunctionType:
      qualityManagementSmartFunctionTypes.ROOT_CAUSE_DISCOVERY,
  },
  {
    id: '3',
    titleKey: 'capaGeneration',
    qualityManagementSmartFunctionType:
      qualityManagementSmartFunctionTypes.CAPA_GENERATION,
  },
]
