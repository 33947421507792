import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DocumentDetailsLayout, SectionLoadingState } from '../../components'
import { DocumentDetailsFormatted } from '../../components/DocumentDetailsLayout'
import { NO_DATA_PLACEHOLDER } from '../../constants/constants'
import { useSearchReviews } from '../../hooks'
import { routePaths } from '../RootPage'

const SearchReviewSearchQueryDocumentDetailsPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const {
    selectedSearchReview,
    selectedSearchQuery,
    selectedDocument,
    summarizeSearchReviewSearchQueryResult,
    refreshSearchReviewList,
    saveSearchQueryInclusion,
    loading,
  } = useSearchReviews(params)
  const [inclusionSwitchLoading, setInclusionSwitchLoading] = useState(false)
  const [fetchDone, setFetchDone] = useState(false)

  useEffect(() => {
    // This will refresh the page whenever the URL changes
    if (!fetchDone) {
      refreshSearchReviewList()
      setFetchDone(true)
    }
  }, [params, refreshSearchReviewList, fetchDone])

  const getDocumentIndex = useCallback(
    () =>
      selectedDocument && selectedSearchQuery?.searchResults
        ? selectedSearchQuery.searchResults.indexOf(selectedDocument)
        : 0,
    [selectedDocument, selectedSearchQuery?.searchResults]
  )
  const getDocumentIndexText = useCallback(
    () =>
      selectedDocument && selectedSearchQuery?.searchResults
        ? getDocumentIndex() + 1
        : NO_DATA_PLACEHOLDER,
    [selectedDocument, selectedSearchQuery?.searchResults, getDocumentIndex]
  )

  const handlePreviousDocumentClick = () => {
    const lastIndex = selectedSearchQuery?.searchResults
      ? selectedSearchQuery?.searchResults.length - 1
      : 0
    const documentIndex = getDocumentIndex()
    const newDocumentIndex = documentIndex > 0 ? documentIndex - 1 : lastIndex
    const newSelectedDocumentId =
      selectedSearchQuery?.searchResults[newDocumentIndex].id
    setFetchDone(false)
    navigate(
      `${routePaths.SEARCH_REVIEW_DOCUMENT}/${selectedSearchReview?.id}/${selectedSearchQuery?.searchEvent}/${newSelectedDocumentId}`
    )
  }

  const handleNextDocumentClick = () => {
    const documentIndex = getDocumentIndex()
    const newDocumentIndex =
      selectedSearchQuery?.searchResults &&
      documentIndex < selectedSearchQuery?.searchResults.length - 1
        ? documentIndex + 1
        : 0
    const newSelectedDocumentId =
      selectedSearchQuery?.searchResults[newDocumentIndex].id
    setFetchDone(false)
    navigate(
      `${routePaths.SEARCH_REVIEW_DOCUMENT}/${selectedSearchReview?.id}/${selectedSearchQuery?.searchEvent}/${newSelectedDocumentId}`
    )
  }

  const isDocumentIncluded = useMemo(
    () =>
      selectedSearchReview?.includedSearchResults?.includes(
        selectedDocument?.id || ''
      ),
    [selectedDocument, selectedSearchReview?.includedSearchResults]
  )

  const handleSwitchClick = useCallback(async () => {
    setInclusionSwitchLoading(true)

    const handleResponse = () => {
      setInclusionSwitchLoading(false)
    }

    try {
      await saveSearchQueryInclusion({
        recordId: selectedDocument?.id || '',
        navigate: navigate,
      })
      handleResponse()
    } catch (error) {
      console.error('axios fetch error', error)
      handleResponse()
    }
  }, [saveSearchQueryInclusion, selectedDocument, navigate])

  const formattedDocumentDetails: DocumentDetailsFormatted = useMemo(
    () => ({
      _id: selectedDocument?.id,
      authors: selectedDocument?.authors,
      dataSource: selectedDocument?.dataSource,
      originalPublicationDate: selectedDocument?.publicationDate || '',
      language: selectedDocument?.language || '',
      publicationName: selectedDocument?.publicationName || '',
      title: selectedDocument?.title || NO_DATA_PLACEHOLDER,
      url: selectedDocument?.documentLink,
      csv: selectedDocument?.csv,
      summary: selectedDocument?.summary,
      summaryStatus: selectedDocument?.summaryStatus,
    }),
    [selectedDocument]
  )
  const handleSummarize = () => {
    if (selectedSearchReview?.id && selectedDocument?.id)
      summarizeSearchReviewSearchQueryResult({
        searchReviewId: selectedSearchReview?.id || '',
        resultId: selectedDocument?.id,
      })
  }

  return selectedDocument && !loading ? (
    <DocumentDetailsLayout
      handleNextDocumentClick={handleNextDocumentClick}
      handlePreviousDocumentClick={handlePreviousDocumentClick}
      getDocumentIndex={getDocumentIndexText}
      length={selectedSearchQuery?.searchResults.length}
      document={formattedDocumentDetails}
      includedSwitchValue={isDocumentIncluded}
      onSwitchClick={handleSwitchClick}
      onSummarize={handleSummarize}
      switchLoading={inclusionSwitchLoading}
      backUrl={`${routePaths.SEARCH_REVIEW_SEARCH}/${selectedSearchReview?.id}/${selectedSearchQuery?.id}`}
    />
  ) : (
    <SectionLoadingState />
  )
}

export default SearchReviewSearchQueryDocumentDetailsPage
