import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import {
  BiomarkModelType,
  OfrBlockType,
  QualityReviewDeviationType,
} from '../../constants/constants'
import {
  AlgorithmRunStatus,
  MissingReferenceCategory,
  PrivacyType,
} from '../../types/generalTypes'
import { DataSource } from './dataSourceReducer'
import { DataStore } from './dataStoreReducer'
import { OfrBlockInfo } from './ofrDocumentTemplateReducer'
import { User } from './userReducer'

export interface WorkspaceFiles {
  id: string
  path: string
  webUrl: string
}

export interface WorkspaceFolder {
  path: string
  folders: WorkspaceFolder[]
  files: WorkspaceFiles[]
}

export interface WorkspaceFolderResponse {
  filesInStructure: WorkspaceFiles[]
  folderStructure: WorkspaceFolder
}

export interface ReferBy {
  id: string
  referById: string
  title: string
  url: string
}

export interface MissingReference {
  missing: string
  missingFile: string
  category: MissingReferenceCategory
  referBy: ReferBy[]
}

export interface ReferenceByDoc {
  docId: string
  docTitle: string
  urls: string
  refs: string[]
  totalTokens: number
}

export interface RefAIRunResult {
  missingReferences: MissingReference[]
  internalReferencesMissing: string[]
  externalReferencesMissing: string[]
  irrelevantReferencesMissing: string[]
  internalClusters?: string
  externalClusters?: string
  referencesByDoc: ReferenceByDoc[]
  totalTokensProcessed: number
  totalDocuments: number
  processedDocument: number
  numberOfReferrers: number
}

export interface RegulatoryItemLocation {
  linkId: string
  linkPath: string
}

export interface RegulatoryItem {
  regulatoryExpectation: string
  locations: RegulatoryItemLocation[]
}

export interface RegulatorySubmissionRunResult {
  absent: string[]
  present: RegulatoryItem[]
}

export interface OfrAnnotationFormPage {
  id: string
  name: string
  type: OfrBlockType
  pageNumber: number
}

export interface OfrDocument {
  id: string
  name: string
  deviations: number
  formPages: OfrAnnotationFormPage[] // renamed
}

export interface DeviatingOfrBlock extends OfrBlockInfo {
  name: string
  qualityReviewDeviation?: QualityReviewDeviationType
  pageNum?: number
  id: string
}

export interface QualityReviewRunResult {
  [fileName: string]: DeviatingOfrBlock[]
}

export interface OfrRunResult {
  documents: OfrDocument[]
}

export interface DelConfigValues {
  // this will be updated once backend is ready
  [key: string]: any
}

export interface DelRunResult {
  // this will be updated once backend is ready
  [key: string]: any
}

export interface QualityReviewConfigValues {
  // note for now documentTemplateId is a string but it will be updated to a list of string once demo is completed (future revision)
  documentTemplateId: string
  documentTemplateName?: string
  documentTemplateFormPages?: OfrAnnotationFormPage[]
}

export interface BiomarkersFeatureData {
  index: number
  columnName: string
  importance: number
  importancePercentage: number
}

export interface BiomarkersRunResult {
  bestMask: number[]
  featureRanking: BiomarkersFeatureData[]
  f1Score: number
}

export interface BiomarkConfigValues {
  maxScore?: number
  trials?: number
  testPercent?: number
  generations?: number
  population?: number
  mutationRate?: number
  numMutants?: number
  numXovers?: number
  numRefines?: number
  modelType?: BiomarkModelType
  language?: string
}

export interface RegulatorySubmissionConfigValues {
  tableOfContentIds: string[]
  fileIds?: string[]
  documentNames?: string[]
}

export type AlgorithmRunItemDetails = OfrDocument // Will contain many in the future "OfrDocument | Another | AnotherAgain

export type AlgorithmRunResult =
  | RefAIRunResult
  | BiomarkersRunResult
  | RegulatorySubmissionRunResult
  | DelRunResult
  | OfrRunResult
  | QualityReviewRunResult

export type AlgorithmRunConfigValues =
  | BiomarkConfigValues
  | RegulatorySubmissionConfigValues
  | DelConfigValues
  | QualityReviewConfigValues

export interface AlgorithmRun {
  id: string
  workspace?: string
  dataSource?: string
  runStatus?: AlgorithmRunStatus
  outputPath?: string
  runResult?: AlgorithmRunResult
  actionBy?: User
  actionDate?: string
  completedDate?: string
  config?: AlgorithmRunConfigValues
  resultPath?: string
}

export type WorkspaceTypeCode =
  | 'REFAI'
  | 'OFR_QR' // OFR (Optical form reader) with quality review
  | 'BIOMARK' // Biomarkers
  | 'DEL' // Drug Establishment License (Pharmascience)
  | 'REGSUB' // Regulatory Submission (World Medicine)
  | 'TRMATRIX' // Traceability Matrix
  | 'QLT_MGMT' // Quality management

export interface WorkspaceConfig {
  excludedMissingReference?: string[]
  ofrDocumentTemplates?: string // string for now -> will be updated to list of string once demo is completed
  ofrDocumentTemplatesName?: string
  ofrDocumentTemplatesId?: string
}

export interface WorkspaceType {
  id: string
  algorithm_url: string
  createdBy: string
  createdDate: string
  lastModifiedBy: string
  lastModifiedDate: string
  contributors: string[]
  type_code: string
  type_name: string
}

interface WorkspaceBase {
  id: string
  clusterId?: string | null
  externalId?: string | null
  createdBy?: string
  createdDate?: string
  lastModifiedBy?: string
  lastModifiedDate?: string
  title: string
  description?: string
  workspaceType?: string
  typeName: string
  typeCode: WorkspaceTypeCode
  algorithmUrl?: string
  visibility: PrivacyType
  algorithmRuns?: AlgorithmRun[]
  algorithmName?: string
  config?: WorkspaceConfig
}

export interface Workspace extends WorkspaceBase {
  dataSource: string
  dataStores?: string[]
  participants?: string[]
}

export interface WorkspaceDetails extends WorkspaceBase {
  dataSource: DataSource
  dataStores?: DataStore[]
  participants?: User[]
}

export interface WorkspaceState {
  workspaceList?: Workspace[]
  workspaceDetailsLoading?: boolean
  selected?: WorkspaceDetails
  workspaceTypeList?: WorkspaceType[]
  selectedAlgorithmRun?: AlgorithmRun
  selectedAlgorithmRunItemDetails?: AlgorithmRunItemDetails
  loading?: boolean
  folders?: WorkspaceFolder[]
  files?: WorkspaceFiles[]
  workspaceFilesLoading?: boolean
  algorithmRunCsv?: string
  algorithmRunListCsv?: string
  listCsv?: string
  detailsCsv?: string
  workspaceDataSourceListCsv?: string
  workspaceDataStoreListCsv?: string
  workspaceParticipantListCsv?: string
}

const initialState: WorkspaceState = {
  workspaceList: [],
  workspaceDetailsLoading: false,
  selected: undefined,
  workspaceTypeList: undefined,
  selectedAlgorithmRun: undefined,
  selectedAlgorithmRunItemDetails: undefined,
  loading: false,
  folders: undefined,
  files: undefined,
  workspaceFilesLoading: false,
  algorithmRunCsv: undefined,
  algorithmRunListCsv: undefined,
  listCsv: undefined,
  detailsCsv: undefined,
  workspaceDataSourceListCsv: undefined,
  workspaceDataStoreListCsv: undefined,
  workspaceParticipantListCsv: undefined,
}

const workspaceSlice = createSlice({
  name: 'workspaces',
  initialState,
  reducers: {
    setWorkspaceList: (state, action: PayloadAction<Workspace[]>) => {
      state.workspaceList = action.payload
    },
    setSelectedWorkspace: (state, action: PayloadAction<WorkspaceDetails>) => {
      state.selected = action.payload
    },
    setWorkspaceTypeList: (state, action: PayloadAction<WorkspaceType[]>) => {
      state.workspaceTypeList = action.payload
    },
    setSelectedAlgorithmRun: (state, action: PayloadAction<AlgorithmRun>) => {
      state.selectedAlgorithmRun = action.payload
    },
    setSelectedAlgorithmRunItemDetails: (
      state,
      action: PayloadAction<AlgorithmRunItemDetails>
    ) => {
      state.selectedAlgorithmRunItemDetails = action.payload
    },
    clearSelectedAlgorithmRun: (state) => {
      state.selectedAlgorithmRun = undefined
    },
    setWorkspaceLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setWorkspaceDetailsLoading: (state, action: PayloadAction<boolean>) => {
      state.workspaceDetailsLoading = action.payload
    },
    setAlgorithmRunListCsv: (state, action: PayloadAction<string>) => {
      state.algorithmRunListCsv = action.payload
    },
    setAlgorithmRunCsv: (state, action: PayloadAction<string>) => {
      state.algorithmRunCsv = action.payload
    },
    setWorkspaceListCsv: (state, action: PayloadAction<string>) => {
      state.listCsv = action.payload
    },
    setWorkspaceDetailsCsv: (state, action: PayloadAction<string>) => {
      state.detailsCsv = action.payload
    },
    setWorkspaceDataSourceListCsv: (state, action: PayloadAction<string>) => {
      state.workspaceDataSourceListCsv = action.payload
    },
    setWorkspaceDataStoreListCsv: (state, action: PayloadAction<string>) => {
      state.workspaceDataStoreListCsv = action.payload
    },
    setWorkspaceParticipantListCsv: (state, action: PayloadAction<string>) => {
      state.workspaceParticipantListCsv = action.payload
    },
    setWorkspaceFilesLoading: (state, action: PayloadAction<boolean>) => {
      state.workspaceFilesLoading = action.payload
    },
    setWorkspaceFolders: (
      state,
      action: PayloadAction<WorkspaceFolder[] | undefined>
    ) => {
      state.folders = action.payload
    },
    setWorkspaceFiles: (state, action: PayloadAction<WorkspaceFiles[]>) => {
      state.files = action.payload
    },
    clearWorkspaceFolders: (state) => {
      state.folders = undefined
      state.files = undefined
      state.workspaceFilesLoading = false
    },
    clearWorkspaceState: () => initialState,
  },
})

export const {
  clearSelectedAlgorithmRun,
  clearWorkspaceState,
  clearWorkspaceFolders,
  setAlgorithmRunCsv,
  setAlgorithmRunListCsv,
  setSelectedAlgorithmRun,
  setSelectedAlgorithmRunItemDetails,
  setSelectedWorkspace,
  setWorkspaceTypeList,
  setWorkspaceDataSourceListCsv,
  setWorkspaceDataStoreListCsv,
  setWorkspaceDetailsCsv,
  setWorkspaceDetailsLoading,
  setWorkspaceFolders,
  setWorkspaceFiles,
  setWorkspaceList,
  setWorkspaceListCsv,
  setWorkspaceLoading,
  setWorkspaceParticipantListCsv,
  setWorkspaceFilesLoading,
} = workspaceSlice.actions

export const getWorkspaces = (state: RootState) => state.workspace.workspaceList

const workspaceReducer = workspaceSlice.reducer
export default workspaceReducer
