import { configureStore } from '@reduxjs/toolkit'
import auditTrailReducer from './reducers/auditTrailReducer'
import dataSourceReducer from './reducers/dataSourceReducer'
import dataStoreReducer from './reducers/dataStoreReducer'
import languageReducer from './reducers/languageReducer'
import searchReviewReducer from './reducers/searchReviewReducer'
import ofrDocumentTemplateReducer from './reducers/ofrDocumentTemplateReducer'
import roleReducer from './reducers/roleReducer'
import searchResultInfoReducer from './reducers/searchResultInfoReducer'
import themeReducer from './reducers/themeReducer'
import userReducer from './reducers/userReducer'
import workspaceReducer from './reducers/workspaceReducer'

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

const reducer = {
  auditTrail: auditTrailReducer,
  dataStore: dataStoreReducer,
  dataSource: dataSourceReducer,
  language: languageReducer,
  searchReview: searchReviewReducer,
  ofrDocumentTemplate: ofrDocumentTemplateReducer,
  role: roleReducer,
  searchResultInfo: searchResultInfoReducer,
  theme: themeReducer,
  user: userReducer,
  workspace: workspaceReducer,
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store
