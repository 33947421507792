import { DeleteOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  BackButton,
  ConfirmDeleteModal,
  DetailsPageLayout,
  HeaderSeparator,
  PageContentDropdown,
  Spacer,
  SubHeader,
} from '../../components'
import { UIFLAGS, urlList } from '../../constants/constants'
import { useSearchReviews, useUiFlags } from '../../hooks'
import { apiService } from '../../services'
import notificationService from '../../services/notificationService'
import { RootState } from '../../store'
import {
  SearchReview,
  setSearchReviewLoading,
  setSelectedSearchReview,
} from '../../store/reducers/searchReviewReducer'
import { routePaths } from '../RootPage'
import SelectWorkspacePanelType from '../WorkspaceDetailsPage/components/SelectWorkspacePanelType'

import {
  SearchReviewDetailsOverviewInfoPanel,
  SearchReviewDetailsOverviewQueriesPanel,
} from './panels'
import SearchReviewTabs from './SearchReviewTabs'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

interface FormValues {
  title: string
  description?: string
}

export type SearchReviewPanelType = 'overview' // The use of camel case is to have camel case in the url
export type SearchReviewPanelTypes = {
  OVERVIEW: SearchReviewPanelType
}

export const searchReviewPanelTypes: SearchReviewPanelTypes = {
  OVERVIEW: 'overview',
}

export type SearchReviewTab = 'info' | 'queries' // The use of camel case is to have camel case in the url
export type SearchReviewTypes = {
  INFO: SearchReviewTab
  QUERIES: SearchReviewTab
}

export const searchReviewTabs = {
  INFO: 'info',
  QUERIES: 'queries',
}

const SearchReviewDetailsPage = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { searchReviewId, panelType, tab } = useParams()

  const {
    selectedSearchReview,
    exportSearchReviewCsv,
    updateSearchReviewDetails,
    deleteSearchReview,
  } = useSearchReviews({
    preventFetch: true,
  })

  const { hasPermission } = useUiFlags({
    uiFlag: selectedSearchReview?.uiFlags || 0,
  })

  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const isCurrentUserOwner = useMemo(
    () => currentUser?.id === selectedSearchReview?.createdBy?.id,
    [selectedSearchReview, currentUser]
  )
  const [saveLoading, setSaveLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)

  const defaultValues: FormValues = useMemo(
    () => ({
      title: selectedSearchReview?.title || '',
      description: selectedSearchReview?.description || '',
      lastUpdate: selectedSearchReview?.lastModifiedDate,
    }),
    [selectedSearchReview]
  )
  const [
    selectedLiteratureReviewPanelType,
    setSelectedLiteratureReviewPanelType,
  ] = useState(
    (panelType || searchReviewPanelTypes.OVERVIEW) as SearchReviewPanelType
  )

  const [selectedLiteratureReviewTab, setSelectedLiteratureReviewTab] =
    useState<SearchReviewTab>((tab || searchReviewTabs.INFO) as SearchReviewTab)

  const panelIndex = useCallback(() => {
    if (selectedLiteratureReviewPanelType === searchReviewPanelTypes.OVERVIEW) {
      switch (selectedLiteratureReviewTab) {
        case searchReviewTabs.INFO:
          return 0
        case searchReviewTabs.QUERIES:
          return 1
      }
    }
    return 0
  }, [selectedLiteratureReviewPanelType, selectedLiteratureReviewTab])

  const refreshSelectedSearchReview = useCallback(() => {
    dispatch(setSearchReviewLoading(true))
    apiService
      .fetchItems(`${urlList.SEARCH_REVIEWS}/${searchReviewId}`)
      .then((response: SearchReview) => {
        dispatch(setSearchReviewLoading(false))
        dispatch(setSelectedSearchReview(response))
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        dispatch(setSearchReviewLoading(false))
      })
  }, [dispatch, searchReviewId])

  useEffect(() => {
    refreshSelectedSearchReview()
  }, [refreshSelectedSearchReview])

  useEffect(() => {
    form.setFieldsValue(defaultValues)
  }, [form, defaultValues])

  const handleSearchDelete = useCallback(() => {
    refreshSelectedSearchReview()
    notificationService.notificationSuccess(
      t('searchReviewDetailsPage.deleteSearchSuccessful') || ''
    )
  }, [refreshSelectedSearchReview])

  const handleDeleteSearchReview = () => {
    setConfirmDeleteModalOpen(true)
  }

  const handleConfirmDelete = () => {
    setDeleteLoading(true)
    deleteSearchReview(selectedSearchReview?.id || '', navigate)
      .then((response: SearchReview) => {
        notificationService.notificationSuccess(
          t('searchReviewDetailsPage.deleteLiteratureReviewSuccessful') ||
            ''
        )
        navigate(routePaths.SEARCH_REVIEW_LIST)
        setDeleteLoading(false)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setDeleteLoading(false)
      })
      .finally(() => {
        setConfirmDeleteModalOpen(false)
      })
  }

  const handleSave = () => {
    setSaveLoading(true)
    const values = form.getFieldsValue()
    updateSearchReviewDetails({
      title: values.title,
      description: values.description || '',
      searchReviewId: selectedSearchReview?.id || '',
      navigate,
    })
      .then(() => {
        notificationService.notificationSuccess(
          t('searchReviewDetailsPage.saveLiteratureReviewSuccessful') || ''
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
      })
      .finally(() => {
        setSaveLoading(false)
      })
  }

  useEffect(() => {
    setSelectedLiteratureReviewPanelType(
      (panelType || searchReviewPanelTypes.OVERVIEW) as SearchReviewPanelType
    )
    setSelectedLiteratureReviewTab(
      (tab || searchReviewTabs.INFO) as SearchReviewTab
    )
  }, [panelType, tab])

  const handleLiteratureReviewExport = () => {
    exportSearchReviewCsv(selectedSearchReview?.id || '')
  }

  const panels = useMemo(
    () => [
      <SearchReviewDetailsOverviewInfoPanel />,
      <SearchReviewDetailsOverviewQueriesPanel
        handleSearchDelete={handleSearchDelete}
      />,
    ],
    [handleSearchDelete]
  )
  return (
    <DetailsPageLayout>
      <SubHeader>
        <BackButton url={routePaths.SEARCH_REVIEW_LIST} />
        <SelectWorkspacePanelType disabled={true} />
        <HeaderSeparator />
        <SearchReviewTabs />
        <Spacer />
        {tab === searchReviewTabs.INFO &&
          panelType === searchReviewPanelTypes.OVERVIEW && (
            <>
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={handleDeleteSearchReview}
                disabled={
                  !hasPermission(UIFLAGS.CAN_DELETE) || !isCurrentUserOwner
                }
              >
                {t('Delete')}
              </Button>
              <ConfirmDeleteModal
                open={confirmDeleteModalOpen}
                onConfirm={handleConfirmDelete}
                onCancel={() => setConfirmDeleteModalOpen(false)}
                loading={deleteLoading}
              >
                {t('searchReviewDetailsPage.confirmDeleteModalContent')}
              </ConfirmDeleteModal>
              <Button
                type="primary"
                style={{ marginLeft: '8px' }}
                onClick={handleSave}
                loading={saveLoading}
                disabled={
                  !hasPermission(UIFLAGS.CAN_UPDATE) || !isCurrentUserOwner
                }
              >
                {t('saveButton')}
              </Button>
            </>
          )}
        <PageContentDropdown onExport={handleLiteratureReviewExport} />
      </SubHeader>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultValues}
        onFinish={handleSave}
        requiredMark={false}
      >
        <ContentContainer>
          {
            panels[panelIndex()] // This is to render the correct panel based on the url
          }
        </ContentContainer>
      </Form>
    </DetailsPageLayout>
  )
}

export default SearchReviewDetailsPage
