import { Tag } from 'antd'
import { ReactNode } from 'react'

export enum BadgeColors {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
  PROCESSING = 'processing',
  WARNING = 'warning',
  DATA_SOURCE = 'cyan',
  ROOT_CAUSE_DISCOVERY = 'gold',
  FILTERS = 'magenta',
  SEARCH_REVIEWS = 'purple',
  TEMPLATE_ANNOTATIONS = 'volcano',
}

export interface BadgeProps {
  color?: BadgeColors | string
  icon?: ReactNode
  children?: ReactNode
}

const getColor = (color?: BadgeColors | string): string => {
  return color && BadgeColors[color as keyof typeof BadgeColors]
    ? BadgeColors[color as keyof typeof BadgeColors]
    : color || BadgeColors.DEFAULT
}

const Badge = ({
  color = BadgeColors.DEFAULT,
  icon = null,
  children = null,
}: BadgeProps) => {
  return (
    <Tag icon={icon} color={getColor(color)}>
      {children}
    </Tag>
  )
}

export default Badge
