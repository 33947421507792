import {
  SearchReview,
  SearchQuery,
} from '../store/reducers/searchReviewReducer'

import { BreadcrumbItemType } from 'antd/lib/breadcrumb/Breadcrumb'
import React from 'react'
import { Link } from 'react-router-dom'
import { routePaths } from '../pages/RootPage'
import { DataSource } from '../store/reducers/dataSourceReducer'
import { User } from '../store/reducers/userReducer'
import { Workspace } from '../store/reducers/workspaceReducer'
import dataSourceService from './dataSourcesService'
import { DataStore } from '../store/reducers/dataStoreReducer'
import { OfrDocumentTemplate } from '../store/reducers/ofrDocumentTemplateReducer'

interface MenuItem {
  key?: React.Key
  title?: React.ReactNode
  label?: React.ReactNode
  path?: string
  href?: string
}

const getWorkspaceMenuItems = (workspaceList: Workspace[]) => {
  if (!workspaceList) return []

  const menuItems: MenuItem[] = workspaceList.map((workspace) => ({
    key: workspace.id,
    label: (
      <Link to={`${routePaths.WORKSPACE_DETAILS}/${workspace.id}`}>
        {workspace.title}
      </Link>
    ),
  }))
  return menuItems
}

const getSearchReviewMenuItems = (
  searchReviewList?: SearchReview[]
) => {
  if (!searchReviewList) return []

  const menuItems: MenuItem[] = searchReviewList.map((review) => ({
    key: review.id,
    label: (
      <Link to={`${routePaths.SEARCH_REVIEW_DETAILS}/${review.id}`}>
        {review.title}
      </Link>
    ),
  }))
  return menuItems
}

const getSearchReviewSearchMenuItems = (
  searchReviewId?: string,
  searchQueryList?: SearchQuery[]
) => {
  const menuItems: MenuItem[] | undefined = searchQueryList?.map((query) => ({
    key: query.searchEvent,
    label: (
      <Link
        to={`${routePaths.SEARCH_REVIEW_SEARCH}/${searchReviewId}/${query.searchEvent}`}
      >
        {query.searchText}
      </Link>
    ),
  }))
  return menuItems || []
}

const getUserMenuItems = (userList: User[]) => {
  if (!userList) return []

  const menuItems: MenuItem[] = userList.map((user) => ({
    key: user.id,
    label: (
      <Link to={`${routePaths.USER_DETAILS}/${user.id}`}>
        {user.displayName}
      </Link>
    ),
  }))
  return menuItems
}

const getBreadcrumbItem = (
  label: string,
  icon?: React.ReactNode,
  path = '',
  menuItems?: MenuItem[]
): BreadcrumbItemType => {
  const content = !!icon ? (
    <>
      {icon} {label}
    </>
  ) : (
    label
  )
  return {
    title: <>{path ? <Link to={path}>{content}</Link> : content}</>,
    menu: menuItems
      ? {
          items: (menuItems as MenuItem[]) || [],
          style: {
            maxHeight: 300,
            overflow: 'auto',
          },
        }
      : undefined,
  }
}

const getDataSourceMenuItems = (dataSourcesList: DataSource[]) => {
  if (!dataSourcesList) return []

  const menuItems: MenuItem[] = dataSourcesList.map((dataSource) => ({
    key: dataSource.id,
    label: (
      <Link to={`${routePaths.DATA_SOURCE_DETAILS}/${dataSource.id}`}>
        {dataSourceService.getDataSourceTitle(dataSource)}
      </Link>
    ),
  }))
  return menuItems
}

const getDataStoreMenuItems = (dataStoresList: DataStore[]) => {
  if (!dataStoresList) return []

  const menuItems: MenuItem[] = dataStoresList.map((dataStore) => ({
    key: dataStore.id,
    label: (
      <Link to={`${routePaths.DATA_STORE_DETAILS}/${dataStore.id}`}>
        {dataStore.title}
      </Link>
    ),
  }))
  return menuItems
}
const getOfrDocumentTemplateMenuItems = (
  ofrDocumentTemplateList: OfrDocumentTemplate[]
) => {
  if (!ofrDocumentTemplateList) return []

  const menuItems: MenuItem[] = ofrDocumentTemplateList.map(
    (documentTemplate) => ({
      key: documentTemplate.id,
      label: (
        <Link
          to={`${routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS}/${documentTemplate.id}`}
        >
          {documentTemplate.title}
        </Link>
      ),
    })
  )
  return menuItems
}

const breadcrumbService = {
  getWorkspaceMenuItems,
  getSearchReviewMenuItems,
  getSearchReviewSearchMenuItems,
  getUserMenuItems,
  getDataSourceMenuItems,
  getDataStoreMenuItems,
  getBreadcrumbItem,
  getOfrDocumentTemplateMenuItems,
}

export default breadcrumbService
