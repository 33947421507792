import {
  EGNYTE_SOURCE_TYPE,
  algorithmRunStatuses,
  workspaceTypes,
} from '../../constants/constants'
import {
  AlgorithmRun,
  WorkspaceDetails,
} from '../../store/reducers/workspaceReducer'

const algorithmRunsArray: AlgorithmRun[] = [
  {
    id: '1',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.ONGOING_AI,
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '2',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.COMPLETE,
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '3',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.FAILED,
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '4',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.READY,
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
  {
    id: '5',
    workspace: undefined,
    dataSource: undefined,
    runStatus: algorithmRunStatuses.CANCELLED,
    outputPath: 'string',
    runResult: undefined,
    actionBy: undefined,
    actionDate: '2023-10-05T15:42:41.893Z',
    completedDate: '2023-10-05T15:42:41.893Z',
  },
]

const workspaceMock: WorkspaceDetails = {
  id: '51748',
  clusterId: null,
  externalId: null,
  title: 'Workspace title',
  description: 'Workspace desc',
  workspaceType: '45064',
  dataSource: {
    id: '51744',
    clusterId: null,
    externalId: null,
    oid: null,
    sourceType: EGNYTE_SOURCE_TYPE,
    title: 'Ref Check DataSet',
    description: 'Ref Check DataSet',
    visibility: 'ORGANIZATION',
    organizationName: 'GenAIz',
    contactName: 'Amine Belaid',
    contactEmail: 'amine.belaid@genaiz.com',
    defaultSource: false,
    config: {
      tenantUid: 'cb391f85-989b-44e8-973d-6ec9c9cacef1',
      sourceFolder: 'RefAI Dataset',
      sourceFolderId:
        'netorg96863.sharepoint.com,cb2b3a66-268a-448e-8dcb-7970168cbd23,b55e3c2c-0539-408c-91eb-c179869a1608',
    },
    participants: [],
    createdBy: '63f79442930987a1621ef75a',
    lastModifiedBy: '63f79442930987a1621ef75a',
    createdDate: '2023-10-04T15:21:11.788Z',
    lastModifiedDate: '2023-10-04T15:21:11.788Z',
    typeName: 'SharePoint',
    typeCode: 'SP',
    isInternal: true,
    searchable: true,
  },
  algorithmRuns: algorithmRunsArray,
  createdBy: '63f79442930987a1621ef75a',
  lastModifiedBy: '63f79442930987a1621ef75a',
  createdDate: '2023-10-05T15:42:41.893Z',
  lastModifiedDate: '2023-10-05T15:42:41.893Z',
  participants: [],
  visibility: 'ORGANIZATION',
  typeName: 'Assets Merger & Acquisition',
  typeCode: workspaceTypes.QLT_MGMT,
  algorithmName: 'Reference Checking',
}

export default workspaceMock
