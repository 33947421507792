import { CalendarOutlined, DatabaseOutlined } from '@ant-design/icons'
import { Divider, FormInstance } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import styled from 'styled-components'
import { Badge, InfoSection } from '../../../../../components'
import { BadgeColors } from '../../../../../components/display/Badge'
import {
  searchReviewSavingTypes,
  searchReviewTypes,
} from '../../../../../constants/constants'
import { DataSource } from '../../../../../store/reducers/dataSourceReducer'
import ModalFormReviewSection from '../../../../DataStoreListPage/modals/component/ModalFormReviewSection'

const BadgesContainer = styled.div`
  display: flex;
  gap: 8px;
`

export interface CreateSearchReviewFinalStepProps {
  form: FormInstance
  query: string
  source?: DataSource
  period?: string
  total?: number
}

const CreateSearchReviewFinalStep = ({
  form,
  query,
  source,
  period,
  total,
}: CreateSearchReviewFinalStepProps) => {
  const reviewType = useMemo(
    () => form.getFieldValue('reviewType') || '',
    [form]
  )

  const savingType = useMemo(
    () => form.getFieldValue('savingType') || '',
    [form]
  )

  const title = useMemo(() => form.getFieldValue('title') || '', [form])

  return (
    <>
      {reviewType && (
        <ModalFormReviewSection
          label={t(
            'createSearchReviewModal.steps.reviewStep.searchReviewTypeTitle'
          )}
          value={
            reviewType === searchReviewTypes.HISTORICAL_NC_REVIEW
              ? t('createSearchReviewModal.steps.typeStep.historicalReviewType')
              : t('createSearchReviewModal.steps.typeStep.literatureReviewType')
          }
        />
      )}
      {savingType && (
        <ModalFormReviewSection
          label={t(
            'createSearchReviewModal.steps.reviewStep.searchReviewNatureTitle'
          )}
          value={
            savingType === searchReviewSavingTypes.EXISTING
              ? t('createSearchReviewModal.steps.setupStep.existingReviewLabel')
              : t('createSearchReviewModal.steps.setupStep.newReviewLabel')
          }
        />
      )}
      {title && (
        <ModalFormReviewSection
          label={t(
            'createSearchReviewModal.steps.reviewStep.searchReviewTitle'
          )}
          value={title}
        />
      )}
      <Divider />
      <InfoSection
        label={t('createSearchReviewModal.steps.definitionStep.inlcusionTitle')}
      >
        <div>{query}</div>
        <BadgesContainer>
          {source?.title && (
            <Badge color={BadgeColors.DATA_SOURCE} icon={<DatabaseOutlined />}>
              {source.typeCode}
            </Badge>
          )}
          {period && (
            <Badge color={BadgeColors.FILTERS} icon={<CalendarOutlined />}>
              {period}
            </Badge>
          )}
        </BadgesContainer>
        <div>
          {t('createSearchReviewModal.steps.definitionStep.totalResult', {
            count: total || 0,
          })}
        </div>
      </InfoSection>
    </>
  )
}

export default CreateSearchReviewFinalStep
