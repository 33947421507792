import { DatabaseOutlined, FilterOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BackButton,
  ListPageLayout,
  PageContentDropdown,
  SearchTable,
  Spacer,
  SubHeader,
} from '../../components'
import Badge, { BadgeColors } from '../../components/display/Badge'
import { SearchTableRow } from '../../components/SearchTable'
import { dateFormat } from '../../constants/constants'
import { useSearchReviews } from '../../hooks'
import i18n from '../../i18n/i18n'
import { RootState } from '../../store'
import { DataSource } from '../../store/reducers/dataSourceReducer'
import { routePaths } from '../RootPage'
import {
  searchReviewPanelTypes,
  searchReviewTabs,
} from '../SearchReviewDetailsPage/SearchReviewDetailsPage'
import {
  BadgesContainer,
  FloatLeftContainer,
} from './SearchReviewSearchQueryDocumentListPage'

const SearchReviewSearchQueryLiteratureReviewListPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [loadingIds, setLoadingIds] = useState<string[]>([])
  const {
    selectedSearchQuery,
    selectedSearchReview,
    exportSearchReviewSearchQueryCsv,
    refreshSearchReviewList,
    saveSearchQueryInclusion,
    getSearchQuerySourceIdForSearchReview,
  } = useSearchReviews(params)

  // refresh every 30 seconds for new updates on the search results summary status
  useEffect(() => {
    const interval = setInterval(() => {
      // we only want to refresh the list if there are no loadingIds
      if (loadingIds.length === 0) refreshSearchReviewList()
    }, 30000)
    return () => clearInterval(interval)
  }, [loadingIds, refreshSearchReviewList])

  const formattedData: SearchTableRow[] = useMemo(
    () =>
      selectedSearchQuery?.searchResults
        ? selectedSearchQuery?.searchResults.map((document, index) => {
            return {
              ...document,
              key: index.toString(),
              id: document?.id || '',
              title: document?.title,
              publicationName: document?.publicationName || '',
              authors: document?.authors,
              publicationDate: document?.publicationDate || '',
              included: selectedSearchReview?.includedSearchResults?.includes(
                document?.id || ''
              ),
              documentLink: document?.documentLink,
              language: document.language || '',
              summaryStatus: document?.summaryStatus || '',
            }
          })
        : [],
    [selectedSearchQuery, selectedSearchReview]
  )
  const dataSources = useSelector(
    (state: RootState) => state.dataSource.dataSourceList
  )
  const source: DataSource | undefined = useMemo(
    () =>
      dataSources.find(
        (source) =>
          source.id ===
          getSearchQuerySourceIdForSearchReview(
            selectedSearchQuery?.searchSource
          )
      ),
    [dataSources, getSearchQuerySourceIdForSearchReview, selectedSearchQuery]
  )
  const sourceText = useMemo(
    () =>
      typeof source?.title === 'string'
        ? source?.title
        : source?.title[i18n.language],
    [source]
  )
  
  const appendLoadingId = (recordId: string) => {
    const allLoadingIds = [...loadingIds, recordId]
    setLoadingIds(allLoadingIds)
  }

  const removeLoadingId = (recordId: string) => {
    const allLoadingIds = loadingIds.filter((id) => id !== recordId)
    setLoadingIds(allLoadingIds)
  }
  const handleOnSwitchClick = async (recordId: string) => {
    // append to loadingIds
    appendLoadingId(recordId)

    const handleResponse = () => {
      removeLoadingId(recordId)
    }

    try {
      await saveSearchQueryInclusion({
        recordId,
        navigate,
      })
      // remove from loadingIds
      handleResponse()
    } catch (error) {
      console.error('axios fetch error', error)
      // remove from loadingIds
      handleResponse()
    }
  }

  const handleExport = () => {
    exportSearchReviewSearchQueryCsv(
      selectedSearchReview?.id || '',
      selectedSearchQuery?.searchEvent || ''
    )
  }

  const handleOnRowClick = (recordId: string) => {
    navigate(
      `${routePaths.SEARCH_REVIEW_DOCUMENT}/${selectedSearchReview?.id}/${params.searchId}/${recordId}`
    )
  }

  return (
    <ListPageLayout>
      <SubHeader>
        <BackButton
          tooltipMessage={
            t('searchReviewSearchDetailsPage.backButtonTooltipMessage') || ''
          }
          url={`${routePaths.SEARCH_REVIEW_DETAILS}/${selectedSearchReview?.id}/${searchReviewPanelTypes.OVERVIEW}/${searchReviewTabs.QUERIES}`}
        />
        <div>
          {t('Query')}: {selectedSearchQuery?.searchText}
        </div>
        <Spacer />
        <FloatLeftContainer>
          <div>
            <Moment
              date={selectedSearchQuery?.searchDate}
              format={dateFormat.PRIMARY}
            />
          </div>
          <BadgesContainer>
            {source && (
              <Badge
                color={BadgeColors.DATA_SOURCE}
                icon={<DatabaseOutlined />}
              >
                {sourceText}
              </Badge>
            )}
            {selectedSearchQuery?.searchParameters.date && (
              <Badge color={BadgeColors.FILTERS} icon={<FilterOutlined />}>
                {selectedSearchQuery?.searchParameters.date}
              </Badge>
            )}
          </BadgesContainer>
          <div>
            {t('Result total')}: {selectedSearchQuery?.searchResults.length}
          </div>
          <PageContentDropdown onExport={handleExport} />
        </FloatLeftContainer>
      </SubHeader>
      <SearchTable
        formattedData={formattedData}
        useInclusionToggle
        onSwitchClick={(recordId) => handleOnSwitchClick(recordId)}
        loadingIds={loadingIds}
        onClick={(recordId) => handleOnRowClick(recordId)}
        maxHeight="calc(100vh - 180px)"
      />
    </ListPageLayout>
  )
}
export default SearchReviewSearchQueryLiteratureReviewListPage
