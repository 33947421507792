import {
  ControlOutlined,
  ExportOutlined,
  EyeOutlined,
  MoreOutlined,
  PlaySquareOutlined,
  PrinterOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { MenuProps } from 'antd/lib'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Location, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  algorithmRunStatuses,
  defaultBiomarkConfig,
  workspaceTypes,
} from '../../../constants/constants'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../hooks'
import { ofrDocumentTemplateService } from '../../../services'
import notificationService from '../../../services/notificationService'
import { OfrDocumentTemplate } from '../../../store/reducers/ofrDocumentTemplateReducer'
import {
  AlgorithmRun,
  BiomarkConfigValues,
  DelConfigValues,
  QualityReviewConfigValues,
  RegulatorySubmissionConfigValues,
  WorkspaceDetails
} from '../../../store/reducers/workspaceReducer'
import { routePaths } from '../../RootPage'
import {
  BiomarkConfigurationModal,
  BiomarkLastConfigurationModal,
} from '../BiomarkSmartFunctionDetailsPage/modals'
import {
  DelConfigurationModal,
  DelLastConfigurationModal,
} from '../DelSmartFunctionDetailsPage/modals'
import {
  RegulatorySubmissionConfigurationModal,
  RegulatorySubmissionLastConfigurationModal,
} from '../RegulatorySubmissionFunctionDetailsPage/modals'

interface SmartFunctionActionDropdownProps {
  algorithmRun?: AlgorithmRun
}

const dropdownKeys = {
  runAlgorithm: 'runAlgorithm',
  cancelRun: 'cancelRun',
  biomarkConfiguration: 'biomarkConfiguration',
  regsubConfiguration: 'regsubConfiguration',
  delConfiguration: 'delConfiguration',
  viewBiomarkConfiguration: 'viewRunSetup',
  viewRegSubRunSetup: 'viewRegSubRunSetup',
  viewDelRunSetup: 'viewDelRunSetup',
  exportRun: 'exportRun',
  printContent: 'printContent',
}

const getBiomarkConfig = (
  algorithmRun: AlgorithmRun,
  defaultBiomarkConfig: BiomarkConfigValues
) => {
  return (
    (algorithmRun.config as BiomarkConfigValues) ||
    defaultBiomarkConfig ||
    undefined
  )
}

const getRegsubConfig = (algorithmRun: AlgorithmRun) => {
  return (algorithmRun.config as RegulatorySubmissionConfigValues) || undefined
}

const getDelConfig = (algorithmRun: AlgorithmRun) => {
  return (algorithmRun.config as DelConfigValues) || undefined
}

const getOfrQrConfig = async (selectedWorkspace: WorkspaceDetails) => {
  const documentTemplateId = selectedWorkspace?.config?.ofrDocumentTemplatesId
  if (!documentTemplateId || documentTemplateId === '') return undefined

  try {
    const response = await ofrDocumentTemplateService.getOfrDocumentTemplate({
      documentId: documentTemplateId,
    })
    const documentTemplate = response as OfrDocumentTemplate
    return {
      documentTemplateId: documentTemplate.documentId,
      documentTemplateFormPages: documentTemplate.formPages,
      documentTemplateName:
        documentTemplate.title ||
        selectedWorkspace?.config?.ofrDocumentTemplatesName,
    } as QualityReviewConfigValues
  } catch (error) {
    console.error('Error fetching document template:', error)
    return undefined
  }
}

const getAlgorithmRunConfig = (
  algorithmRun: AlgorithmRun | undefined,
  selectedWorkspace: WorkspaceDetails | undefined
) => {
  if (!algorithmRun) return undefined
  switch (selectedWorkspace?.typeCode) {
    case workspaceTypes.BIOMARK:
      return getBiomarkConfig(algorithmRun, defaultBiomarkConfig)
    case workspaceTypes.REGSUB:
      return getRegsubConfig(algorithmRun)
    case workspaceTypes.DEL:
      return getDelConfig(algorithmRun)
    case workspaceTypes.OFR_QR:
    case workspaceTypes.REFAI:
    default:
      return undefined
  }
}

const SmartFunctionActionDropdown = ({
  algorithmRun,
}: SmartFunctionActionDropdownProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [fetchDone, setFetchDone] = useState(false)
  const [selectedAlgorithmRunId, setSelectedAlgorithmRunId] = useState<
    string | undefined
  >(algorithmRunId)

  const { selectedWorkspace, refreshWorkspaceDetails, exportSmartFunctionCsv } =
    useWorkspaceDetails({
      workspaceId,
      preventFetch: true,
    })

  const { refreshAlgorithmRun, runAlgorithm, cancelAlgorithmRun } =
    useAlgorithmRunDetails({
      algorithmRunId: selectedAlgorithmRunId,
      workspaceId,
      preventFetch: true,
    })

  const isStatusOngoing = useMemo(
    () =>
      !!selectedWorkspace?.algorithmRuns?.find(
        (run) =>
          run.runStatus === algorithmRunStatuses.ONGOING_AI ||
          run.runStatus === algorithmRunStatuses.ONGOING_INGESTION
      ),
    [selectedWorkspace?.algorithmRuns]
  )

  const isRunsAvailable = useMemo(() => {
    if (
      selectedWorkspace?.algorithmRuns &&
      selectedWorkspace?.algorithmRuns?.length > 0 &&
      !isStatusOngoing
    ) {
      return true
    }

    if (selectedWorkspace?.typeCode === workspaceTypes.REFAI) {
      return true
    }

    if (selectedWorkspace?.typeCode === workspaceTypes.OFR_QR) {
      if (selectedWorkspace?.config?.ofrDocumentTemplates) {
        return true
      }
    }
    return false
  }, [isStatusOngoing, selectedWorkspace])

  const commonItems: MenuProps['items'] = [
    {
      key: dropdownKeys.runAlgorithm,
      icon: <PlaySquareOutlined />,
      disabled: !isRunsAvailable,
      label: t(
        'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.runAlgorithm'
      ),
    },
    {
      key: dropdownKeys.cancelRun,
      icon: <StopOutlined />,
      disabled: !isStatusOngoing,
      label: t(
        'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.cancelRun'
      ),
    },
    ...(algorithmRunId
      ? [
          {
            key: dropdownKeys.printContent,
            disabled: algorithmRun?.runStatus !== algorithmRunStatuses.COMPLETE,
            icon: <PrinterOutlined />,
            label: t(
              'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.printContent'
            ),
          },
        ]
      : []),
    ...(selectedWorkspace?.typeCode !== workspaceTypes.REGSUB &&
    selectedWorkspace?.typeCode !== workspaceTypes.DEL
      ? [
          {
            key: dropdownKeys.exportRun,
            disabled: algorithmRun?.runStatus !== algorithmRunStatuses.COMPLETE,
            icon: <ExportOutlined />,
            label: t(
              'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.exportRun'
            ),
          },
        ]
      : []),
  ]

  const biomarkItems: MenuProps['items'] = [
    {
      key: dropdownKeys.biomarkConfiguration,
      icon: <ControlOutlined />,
      label: (
        <BiomarkConfigurationModal
          config={
            getAlgorithmRunConfig(algorithmRun, selectedWorkspace) as
              | BiomarkConfigValues
              | undefined
          }
        />
      ),
    },
    {
      key: dropdownKeys.viewBiomarkConfiguration,
      icon: <EyeOutlined />,
      label: (
        <BiomarkLastConfigurationModal
          config={
            getAlgorithmRunConfig(algorithmRun, selectedWorkspace) as
              | BiomarkConfigValues
              | undefined
          }
        />
      ),
    },
  ]

  const regsubItems: MenuProps['items'] = [
    {
      key: dropdownKeys.regsubConfiguration,
      icon: <ControlOutlined />,
      label: (
        <RegulatorySubmissionConfigurationModal
          config={
            getAlgorithmRunConfig(algorithmRun, selectedWorkspace) as
              | RegulatorySubmissionConfigValues
              | undefined
          }
        />
      ),
    },
    {
      key: dropdownKeys.viewRegSubRunSetup,
      icon: <EyeOutlined />,
      label: (
        <RegulatorySubmissionLastConfigurationModal
          config={
            getAlgorithmRunConfig(algorithmRun, selectedWorkspace) as
              | RegulatorySubmissionConfigValues
              | undefined
          }
        />
      ),
    },
  ]

  const delItems: MenuProps['items'] = [
    {
      key: dropdownKeys.delConfiguration,
      icon: <ControlOutlined />,
      label: (
        <DelConfigurationModal
          config={
            getAlgorithmRunConfig(algorithmRun, selectedWorkspace) as
              | DelConfigValues
              | undefined
          }
        />
      ),
    },
    {
      key: dropdownKeys.viewDelRunSetup,
      icon: <EyeOutlined />,
      label: (
        <DelLastConfigurationModal
          config={
            getAlgorithmRunConfig(algorithmRun, selectedWorkspace) as
              | DelConfigValues
              | undefined
          }
        />
      ),
    },
  ]

  const getItemsBasedOnSmartFunctionTypeCode = () => {
    const selectedItems = [...commonItems]

    switch (selectedWorkspace?.typeCode) {
      case workspaceTypes.BIOMARK:
        selectedItems.splice(1, 0, ...biomarkItems)
        return selectedItems
      case workspaceTypes.REGSUB:
        selectedItems.splice(1, 0, ...regsubItems)
        return selectedItems
      case workspaceTypes.DEL:
        selectedItems.splice(1, 0, ...delItems)
        return selectedItems
      default:
        return selectedItems
    }
  }

  const items: MenuProps['items'] = getItemsBasedOnSmartFunctionTypeCode()

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }, [])

  useEffect(() => {
    if (algorithmRunId && algorithmRunId !== selectedAlgorithmRunId) {
      setSelectedAlgorithmRunId(algorithmRunId)
      setFetchDone(false)
    }
  }, [algorithmRunId, location.pathname, selectedAlgorithmRunId])

  useEffect(() => {
    if (selectedAlgorithmRunId && !fetchDone) {
      refreshWorkspaceDetails()
      refreshAlgorithmRun()
      setFetchDone(true)
    }
  }, [
    fetchDone,
    refreshAlgorithmRun,
    refreshWorkspaceDetails,
    selectedAlgorithmRunId,
  ])

  const getBaseRoute = (
    selectedWorkspace: WorkspaceDetails,
    location: Location
  ) => {
    switch (selectedWorkspace?.typeCode) {
      case workspaceTypes.OFR_QR:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR
      case workspaceTypes.BIOMARK:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK
      case workspaceTypes.REGSUB:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB
      case workspaceTypes.DEL:
        return routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL
      case workspaceTypes.REFAI:
        return location.pathname.includes(
          routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
        )
          ? routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
          : routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
      default:
        return ''
    }
  }

  const handleAlgorithmRunClick = useCallback(async () => {
    try {
      let config = await getAlgorithmRunConfig(algorithmRun, selectedWorkspace)
      if (selectedWorkspace?.typeCode === workspaceTypes.OFR_QR) {
        config = await getOfrQrConfig(selectedWorkspace)
        if (!config) {
          notificationService.notificationError(
            t('smartFunctionDetailsPage.runFailed')
          )
          return
        }
      }
      if (!config && selectedWorkspace?.typeCode !== workspaceTypes.REFAI) {
        return undefined
      }

      const response = await runAlgorithm(workspaceId || '', config)

      if (!location.pathname.includes(routePaths.WORKSPACE_DETAILS)) {
        if (!selectedWorkspace) return
        if (!location.pathname.includes(routePaths.WORKSPACE_DETAILS)) return
        const baseRoute = getBaseRoute(selectedWorkspace, location)
        const targetRoute = `${baseRoute}/${selectedWorkspace?.id}/${response.id}`
        navigate(targetRoute)
        setSelectedAlgorithmRunId(response.id)
      } else {
        refreshWorkspaceDetails()
      }

      notificationService.notificationSuccess(
        t('smartFunctionDetailsPage.runStarted')
      )
    } catch (error) {
      console.error('Error running algorithm:', error)
      notificationService.notificationError(
        t('smartFunctionDetailsPage.runFailed')
      )
    }
  }, [
    algorithmRun,
    selectedWorkspace,
    runAlgorithm,
    workspaceId,
    location,
    navigate,
    refreshWorkspaceDetails,
  ])

  const handleCancelRunClick = useCallback(() => {
    cancelAlgorithmRun(
      selectedWorkspace?.id || '',
      algorithmRun?.id || '',
      navigate
    )
      .then(() => {
        refreshWorkspaceDetails()
        refreshAlgorithmRun()
        notificationService.notificationWarning(
          t('smartFunctionDetailsPage.runCancelled')
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
      })
  }, [
    algorithmRun,
    cancelAlgorithmRun,
    navigate,
    refreshAlgorithmRun,
    refreshWorkspaceDetails,
    selectedWorkspace,
  ])

  const handlePrintContent = useCallback(() => {
    window.print()
  }, [])

  const handleExportRun = () => {
    if (selectedWorkspace && algorithmRun) {
      exportSmartFunctionCsv(selectedWorkspace.id, algorithmRun.id)
    }
  }

  const handleDropdownClick = (key: string) => {
    const handles = {
      [dropdownKeys.runAlgorithm]: handleAlgorithmRunClick,
      [dropdownKeys.cancelRun]: handleCancelRunClick,
      [dropdownKeys.exportRun]: handleExportRun,
      [dropdownKeys.printContent]: handlePrintContent,
    }
    const handler = handles[key]
    if (handler) {
      handler()
    }
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: ({ key, domEvent }) => {
          domEvent.stopPropagation()
          handleDropdownClick(key)
        },
      }}
      placement="bottomRight"
      trigger={['hover']}
    >
      <Button
        icon={<MoreOutlined />}
        onClick={(e) => {
          handleOpenMenu(e)
        }}
      />
    </Dropdown>
  )
}

export default SmartFunctionActionDropdown
