import { Select } from 'antd'
import { useEffect, useState, useMemo, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchReviewType, urlList } from '../constants/constants'
import { apiService } from '../services'
import { RootState } from '../store'
import {
  SearchReview,
  setSearchReviewList,
} from '../store/reducers/searchReviewReducer'
import { AxiosError } from 'axios'

interface SelectOption {
  label: string
  value: string
}

interface SelectSearchReviewProps {
  onChange: (value: SearchReview | undefined) => void
  style?: Object
  selectedType?: SearchReviewType
}

const SelectSearchReview = memo(
  ({ onChange, style, selectedType }: SelectSearchReviewProps) => {
    const dispatch = useDispatch()
    const searchReviews = useSelector(
      (state: RootState) => state.searchReview?.searchReviewList
    )

    const options: SelectOption[] | undefined = useMemo(
      () =>
        searchReviews
          ?.filter(
            (searchReview: SearchReview) =>
              !!searchReview.id &&
              (!selectedType || searchReview.reviewType === selectedType)
          )
          .map((searchReview: SearchReview) => ({
            label: searchReview.title || '',
            value: searchReview.id || '',
          })),
      [searchReviews, selectedType]
    )
    const [loading, setLoading] = useState(true)
    const [selectedValue, setSelectedValue] = useState('')

    const handleChange = (value: string) => {
      setSelectedValue(value)
      if (searchReviews) {
        onChange(
          searchReviews.find(
            (searchReview: SearchReview) => searchReview.id === value
          )
        )
      }
    }

    useEffect(() => {
      apiService
        .fetchItems(urlList.SEARCH_REVIEWS + '?ownedOnly=true')
        .then((response: SearchReview[]) => {
          dispatch(setSearchReviewList(response))
        })
        .catch((error: AxiosError | Error) => {
          console.error('axios fetch error', error)
        })
        .finally(() => {
          setLoading(false)
        })
    }, [dispatch])

    return (
      <Select
        style={style}
        onChange={handleChange}
        value={selectedValue}
        options={options}
        loading={loading}
      />
    )
  }
)

export default SelectSearchReview
