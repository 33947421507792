import { Form, FormInstance, Radio, Space } from 'antd'
import { t } from 'i18next'
import { searchReviewTypes } from '../../../../../constants/constants'

export interface CreateSearchReviewTypeStepProps {
  form: FormInstance
}

const CreateSearchReviewTypeStep = ({
  form,
}: CreateSearchReviewTypeStepProps) => {
  return (
    <Form.Item
      label={t('createSearchReviewModal.steps.typeStep.subHeader')}
      required
      rules={[{ required: true, message: t('errorMessage.isRequired') || '' }]}
      name="reviewType"
    >
      <Radio.Group>
        <Space direction="vertical">
          <Radio value={searchReviewTypes.HISTORICAL_NC_REVIEW}>
            {t(
              'createSearchReviewModal.steps.typeStep.historicalReviewType'
            )}
          </Radio>
          <Radio value={searchReviewTypes.LITERATURE_REVIEW}>
            {t(
              'createSearchReviewModal.steps.typeStep.literatureReviewType'
            )}
          </Radio>
        </Space>
      </Radio.Group>
    </Form.Item>
  )
}

export default CreateSearchReviewTypeStep
